import type { Store } from '../../../store';
import { appSettingsSlice } from '../../../store/slices';
import type {
  DesignSubTab,
  SettingsEventHandler,
  SettingsTab,
} from '../../../types/appSettings';
import { SettingsEvent } from '../../../types/appSettings';

export const registerSettingEventHandlers = (
  settingsEventHandler: SettingsEventHandler,
  store: Store,
) => {
  settingsEventHandler.on(
    SettingsEvent.TabChanged,
    (settingsTab: SettingsTab) => {
      store.dispatch(appSettingsSlice.actions.settingsTabChange(settingsTab));
    },
  );
  settingsEventHandler.on(
    SettingsEvent.SubTabChanged,
    (subTab: DesignSubTab) => {
      store.dispatch(appSettingsSlice.actions.settingsSubTabChange(subTab));
    },
  );
  settingsEventHandler.onReset(() => {
    store.dispatch(appSettingsSlice.actions.settingsTabChange(null));
    store.dispatch(appSettingsSlice.actions.settingsSubTabChange(null));
  });
};
