import type {
  Field,
  MediaPlatformImage,
  Member,
  PublicInfo,
} from '../../../types';
import { PublicInfoFieldId } from '../../../types';

export const hasMemberAvatarURLChanged = (
  updatedPublicInfo: PublicInfo,
  currentProfileInfo: PublicInfo,
) => updatedPublicInfo.picture?.url !== currentProfileInfo.picture?.url;

export const getPublicInfoWithMediaPicture = (
  initialProfileInfo: PublicInfo,
  currentProfileInfo: PublicInfo,
  profileCardPicture: MediaPlatformImage | undefined,
) => ({
  name: currentProfileInfo.name,
  title: currentProfileInfo.title,
  ...(hasMemberAvatarURLChanged(initialProfileInfo, currentProfileInfo) && {
    picture: profileCardPicture,
  }),
});

export const getPublicInfo = (fields: Field[], member: Member) => {
  const nameFieldValue = fields.find(
    ({ id }) => id === PublicInfoFieldId.DISPLAY_NAME,
  )?.value as string;
  const titleFieldValue = fields.find(
    ({ id }) => id === PublicInfoFieldId.TITLE,
  )?.value as string | undefined;

  return {
    name: nameFieldValue,
    title: titleFieldValue,
    picture: member.profile.photo,
  };
};
