import { combineReducers } from '@reduxjs/toolkit';

import {
  appSettingsSlice,
  fieldsSlice,
  localeSlice,
  memberSlice,
  siteSlice,
  uiSlice,
} from './slices';

export const rootReducer = combineReducers({
  member: memberSlice.reducer,
  fields: fieldsSlice.reducer,
  site: siteSlice.reducer,
  ui: uiSlice.reducer,
  locale: localeSlice.reducer,
  appSettings: appSettingsSlice.reducer,
});

export type RootState = ReturnType<typeof rootReducer>;
