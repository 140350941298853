import { createSlice } from '@reduxjs/toolkit';

import type { Member } from '../../../types';
import { extraReducers } from './extraReducers';
import { setBlockedMembers, setMember, setSlug } from './reducers';

export type State = Member;

const initialState = null! as State;

export const memberSlice = createSlice({
  name: 'member',
  initialState,
  reducers: {
    setBlockedMembers,
    setSlug,
    setMember,
  },
  extraReducers,
});
