import { withResolvedError } from '@wix/members-area-commons-ts';

import {
  membersApiMediaIndexURL,
  membersApiPictureURL,
} from '../../../../../constants/urls';
import type { FlowApi, MediaPlatformImage } from '../../../../../types';

type MediaCredentialsResponse = {
  uploadToken: string;
  uploadUrl: string;
};

type UploadImageToMediaStoreOptions = {
  formData: FormData;
  uploadUrl: string;
  flowAPI: FlowApi;
};

type Picture = {
  width?: number;
  height?: number;
  file_name?: string;
  original_file_name?: string;
};

const mediaType = 'picture';

const membersAreaFolder = 'Members Area';

const visitorsUploadsFolderId = 'visitor-uploads';

const getMediaCredentials = async ({ httpClient, errorHandler }: FlowApi) => {
  const requestFn = () =>
    httpClient.get<MediaCredentialsResponse>(membersApiPictureURL);

  const { data } = await withResolvedError(requestFn, errorHandler, {
    errorCodesMap: {},
  });

  return data;
};

const dataUrlToBlob = async (
  url: string,
  { httpClient, errorHandler }: FlowApi,
) => {
  const requestFn = () =>
    httpClient.get<Blob>(url, {
      responseType: 'blob',
    });

  const { data } = await withResolvedError(requestFn, errorHandler, {
    errorCodesMap: {},
  });

  return data;
};

const uploadImageToMediaStore = async ({
  uploadUrl,
  formData,
  flowAPI,
}: UploadImageToMediaStoreOptions) => {
  const { httpClient, errorHandler } = flowAPI;

  const requestFn = () =>
    httpClient.post<MediaPlatformImage[]>(uploadUrl, formData);

  const { data } = await withResolvedError(requestFn, errorHandler, {
    errorCodesMap: {},
  });

  return data;
};

const setMediaPlatformPictureDetails = (
  picture: Picture,
  { httpClient, errorHandler }: FlowApi,
) => {
  const requestFn = () =>
    httpClient.post(membersApiMediaIndexURL, JSON.stringify(picture), {
      headers: { 'Content-Type': 'application/json' },
    });

  return withResolvedError(requestFn, errorHandler, {
    errorCodesMap: {},
  });
};

export const uploadImage = async (
  flowAPI: FlowApi,
  fileName: string | undefined,
  fileDataUrl: string,
) => {
  const { uploadToken, uploadUrl } = await getMediaCredentials(flowAPI);

  const formData = new FormData();
  const blob = await dataUrlToBlob(fileDataUrl, flowAPI);

  formData.append('directory', membersAreaFolder);
  formData.append('parent_folder_id', visitorsUploadsFolderId);
  formData.append('upload_token', uploadToken);
  formData.append('media_type', mediaType);
  formData.append('file', blob, fileName);

  const [mediaPicture] = await uploadImageToMediaStore({
    uploadUrl,
    formData,
    flowAPI,
  });

  const { width, height, file_name, original_file_name } = mediaPicture;
  const picture = { width, height, file_name, original_file_name };

  await setMediaPlatformPictureDetails(picture, flowAPI);

  return picture;
};
