import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import type { DesignSubTab, SettingsTab } from '../../../types/appSettings';

export type State = {
  openTab: SettingsTab | null;
  openSubTab: DesignSubTab | null;
};

const initialState: State = {
  openTab: null,
  openSubTab: null,
};

export const appSettingsSlice = createSlice({
  name: 'appSettings',
  initialState,
  reducers: {
    settingsTabChange: (state, action: PayloadAction<SettingsTab | null>) => {
      state.openTab = action.payload;
      state.openSubTab = null;
    },
    settingsSubTabChange: (
      state,
      action: PayloadAction<DesignSubTab | null>,
    ) => {
      state.openSubTab = action.payload;
    },
  },
});
